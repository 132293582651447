<template>
  <div>
    <CRow>
      <CCol md="12">
        <CCard>
          <CCardHeader>
            <strong>Job Task</strong>
          </CCardHeader>
         <CRow>
         <CCol md="10"> 

          <CCardBody>
            <CForm>
           
              <CInput
                label="FirstName "
                v-model="jobtask.firstName"
                horizontal
               
                :disabled="mode.viewMode"
                 :invalid-feedback="'Please Enter the Task Name'"
                 
             />
             <CInput
                label="Last Name"
                v-model="jobtask.lastName"
                horizontal
               
                :disabled="mode.viewMode"
                 :invalid-feedback="'Please Enter the Task Name'"
                   
                  
             />
             <CInput
                label="Action To Be Taken"
                v-model="jobtask.actionToBeTaken"
                horizontal
               
                :disabled="mode.viewMode"
                  
             />
             <CInput
                label="Condition Desc"
                v-model="jobtask.conditionDesc"
                horizontal               
                :disabled="mode.viewMode"
                  
             />
             <CInput
                label="Severity"
                v-model="jobtask.severity"
                horizontal               
                :disabled="mode.viewMode"
                
             />
             <!-- <div>
       <vue-images :imgs="jobtask.civilTaskImageList" :modalclose="true">
       </vue-images>
             </div> -->
         <!-- <CInputFile 
                  label="Image" 
                  horizontal 
                   type="file"
                 :disabled="mode.viewMode"
                  accept="image/*"
                  @change="uploadImage"
            
                 /> -->
                  <!--  -->
                   <!-- <CRow>
                    <CCol md="3"></CCol>
                    <CCol md="9" align="center">
                      <CCard v-if="jobtask.civilTaskImageList.imageId && !$v.jobtask.civilTaskImageList.imageId.$error" style="margin-top: 20px">
                      
                        <CCardBody align="center">
                       <CButton color="danger"  style="float: right" @click="removeimage() ">
                        <CIcon   name="cilXCircle" description="Delete"></CIcon>
                         </CButton> 
                          <CImg
                            v-if="jobtask.civilTaskImageList.imageId"
                            :src="imageUrl(jobtask.civilTaskImageList.imageId)"
                            height="180px"
                            alt="Responsive image"
                            :disabled="mode.viewMode"
                          > </CImg>
                        </CCardBody>
                      </CCard>
                    </CCol>
                  </CRow> -->
                
            </CForm>
           
          </CCardBody>
          </CCol>
          
          </CRow>
          
          <CCardFooter align="right">
        
            <CButton class="mr-2" type="Cancel" size="sm" color="danger"  @click="cancel()">
              <CIcon name="cil-ban" /> Cancel
            </CButton>
          </CCardFooter>
        </CCard>
       
        <CCard>           
  <CCardBody>
    
    <CDataTable :items="items"  
    :fields="fields"
      striped
      clickable-rows
      column-filter
       table-filter
        items-per-page-select
      :items-per-page="20"
       sorter
       ref="vuetable"
      pagination
       :responsive="false">
       
      <template #index="data">
       <td>
         {{data.index +1}}
         </td>
     </template>
 
 <template #imageId="{item}">
        <td v-if="item.imageId">
         <img :src="getImageUrl(item.imageId)" style='max-width:100px;max-height: 100px;'>

        </td>

      </template>
      <!-- <template #createdTime="{item}">
        <td class='font-weight-bold'
          v-if="item.createdTime"
        >{{ item.createdTime |dateParse()| dateFormat("MMM D, YYYY hh:mm A") }}</td>
        <td v-else>--</td>
      </template>
      -->
     
        <template #show_details="{item}">
        <td class="py-2">
        <CDropdown
                  togglerText="Actions"
                  color="info"
                >
               
                   <CDropdownItem :to="{name: 'Task Image', params: {taskImageId: item.taskImageId, mode: 'view'}}">View</CDropdownItem>
                 
                </CDropdown> 

        
        </td>
      </template>
      </CDataTable>
  </CCardBody>
  <div ref="modalArea"></div>
<CCardFooter align="right">
         
            <CButton class="mr-2" type="Cancel" size="sm" color="danger"  @click="cancel()">
              <CIcon name="cil-ban" /> Cancel
            </CButton>
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
const fields = [
  'index',
 
   
    { key: "jobTaskId",label: "Task Id", _classes: 'font-weight-bold',  _style: "min-width:200px" },
    { key: "imageId",label: "Image", _classes: 'font-weight-bold',  _style: "min-width:200px" },
  
  
  { key: "show_details",
    label: "",
    _style: "width:1%",
    sorter: false,
    filter: false,
  },
  
];
// import vueImages from 'vue-images'
import vueImages from 'vue-images/dist/vue-images'
 import Vue from "vue";
 import datetime from 'vuejs-datetimepicker';
import {
  required,
  minLength,
  maxLength,
  url,
  requiredIf,
} from "vuelidate/lib/validators";
export default {
  name: "Job Task",
  data() {
    return {
       items: [],
      fields,
      details: [],
      collapseDuration: 0,
      mode:{
        newMode:false,
        editMode:false,
        viewMode:false,
      },
       
     jobTaskId:"",
    
     jobtask:{
          jobTaskId:"0",
    jobId:"0",
     firstName:'',
     lastName:'',
     actionToBeTaken:'',
     conditionDesc:'',
     severity:'',
     civilTaskImageList:{
         fileName: "",
       imageId: 0,
        jobTaskId: 0,
        taskImageId: 0
     },
      },  
      horizontal: { label: "col-3", input: "col-9" },
    };
 },
 components: {
    vueImages
  },
   created() {  
             if (this.$route.params.mode == "new") {
                      this.mode.newMode = true;
                        this.mode.viewMode = false;
                        this.mode.editMode = false;
                         this.jobId = this.$route.params.jobId;
                 }
             if(this.$route.params.mode =='view'){
                   this.mode.viewMode=true;
                    this.jobId = this.$route.params.jobId;
                      this.jobTaskId = this.$route.params.jobTaskId;
                    this.getUser();
            }
            if (this.$route.params.mode == "edit") {
                this.mode.editMode = true;
                this.jobId = this.$route.params.jobId;
                 this.jobTaskId = this.$route.params.jobTaskId;
                 this.getUser();
    }
        },
         
  methods: {
     getImageUrl(imageId) {
      console.log(imageId);
      return process.env.VUE_APP_API_HOST + "/emapp/file/" + imageId;
    },

    editMode(){
       this.mode.editMode=true;
        this.mode.viewMode=false;
    },
     cancel() {
  
             this.$router.push({ name: "Job Tasks",  params: {jobId: this.$route.params.jobId} });
    },
  
    validator(val) {
      return val ? val.length >= 4 : false;
    },

    
    getUser() {
     this.apiGetSecure(process.env.VUE_APP_API_HOST +"/emapp/web/secure/get/civil/task/"+ this.jobTaskId)
        .then((response) => response.json())
        .then( (data)  => (this.jobtask = data) ); 
    },

      getImages() {
     this.apiGetSecure(process.env.VUE_APP_API_HOST +"/emapp/web/secure/get/civil/job/tasks/images/"+ this.jobTaskId)
        .then((response) => response.json())
        .then( (data)  => (this.items = data) ); 
    },
   
   
  },
  mounted() {
   this.getImages();
  }
};
</script>
